import React from "react";

import DemoSetting from "./DemoSetting";
import { apiGet, apiPost } from "../common/API";

class DemoSettingController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'list',
    };
  }

  componentDidMount() {
  }

  backToMenu(e) {
    e.preventDefault();
    this.props.backToMenu();
  }

  render() {
    const {mode} = this.state;
    switch (mode) {
      case '':
        return <DemoSetting {...this.props} handleBack={e => this.backToMenu(e)}/>

    }
    return <DemoSetting {...this.props} handleBack={e => this.backToMenu(e)} />
  }
}

export default DemoSettingController;
