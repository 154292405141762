import React, {useCallback, useEffect, useState} from "react";
import {
  durationFormat,
  getClipSequence,
  getEndPoint,
  getObjectStorageEndPoint,
  startTimeFormat
} from "../../../lib/util"

import { SF_YEAR, getDriverNameRows } from "../../../lib/util";
import {isCursorAtEnd} from "@testing-library/user-event/dist/utils";

const OperationModal = (props) => {
  const { loginUser, wideOperationModal } = props;
  const { source, clipType, car, content, locked } = props.operationClip;
  const clip_id = source.is_finalized ? ('c' + source.customclip_id) : source.autoclip_id;
  const playState = props.playingClips.hasOwnProperty(clip_id) ? props.playingClips[clip_id] : null;
  const playStateStyle = playState ? {display: "block", left: (playState.progress * 100) + '%'} : {display: "none"};
  const [ draggingState, setDraggingState ] = useState({target: null, startX: 0});

  const startMoveCue = (state) => {
    setDraggingState(state);
    moveCue(state.startX, state.target);
    if (playState) {
      props.handleStopClip(source.autoclip_id);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      props.handleClose();
    } else if (event.key === ' ') {
      // playState ? props.handleStopClip(clip_id) : props.handlePlayClip(source, 1, content);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)
  }, [])

  const clearDraggingState = () => {
    setDraggingState({target: null, startX: 0});
  }

  const moveCue = (x, target = null) => {
    if (!target) {
      target = draggingState.target;
    }
    const cue = document.getElementById('cue');
    const cueInner = document.getElementById('cue-inner');
    const gap = (cue.clientWidth - cueInner.clientWidth) / 2 * (target === 'start' ? 1 : -1);
    let pos = ((x - cueInner.getBoundingClientRect().left + gap / 2) / cueInner.clientWidth);
    let leftMax = Math.max(content.cueStart, 0.05);
    let rightMax = Math.min(content.cueEnd, 0.95);
    if (target === 'start') {
      if (pos < 0 ) {
        pos = 0;
      }
      if (pos > rightMax) {
        pos = rightMax;
      }
      props.handleChangeOperationContent('cueStart', pos);
    } else if (target === 'end') {
      if (pos > 1) {
        pos = 1;
      }
      if (pos < leftMax) {
        pos = leftMax;
      }
      props.handleChangeOperationContent('cueEnd', pos);
    }

  }


  const clickBeep = (beepIndex, beep, clientY) => {
    const waveFormArea = document.getElementById('cue');
    const waveFormRect = waveFormArea.getBoundingClientRect();
    if (clientY >= waveFormRect.top && clientY <= waveFormRect.bottom) {
      props.handleChangeOperationContent({beepIndex: beepIndex, marker: 'type'}, beep.type==='cut' ? 'beep' : 'cut');
    }
  }

  const addBeep = (clientX) => {
    if (playState) {
      props.handleStopClip(source.autoclip_id);
    }

    const beep = document.getElementById('beep-setting');
    let pos = ((clientX - beep.getBoundingClientRect().left) / beep.clientWidth);
    props.handleAddBeep(pos, 'cut');
  }

  const startMoveBeep = (state) => {
    if (playState) {
      props.handleStopClip(source.autoclip_id);
    }
    setDraggingState(state);
    moveBeep(state.startX, state.target);
    if (playState) {
      props.handleStopClip(source.autoclip_id);
    }
  };

  const moveBeep = (x, target = null) => {
    if (!target) {
      target = draggingState.target;
    }
    const beep = document.getElementById('beep-setting');
    const currentBeep = content.editInfo.beepList[target.beepIndex];
    let pos = ((x - beep.getBoundingClientRect().left) / beep.clientWidth);
    let leftMax = content.editInfo.beepList.filter(beep => beep.end <= currentBeep.start).reduce((startPos, beep) => Math.max(startPos, beep.end), content.cueStart);
    let rightMax = content.editInfo.beepList.filter(beep => beep.start >= currentBeep.end).reduce((endPos, beep) => Math.min(endPos, beep.start), content.cueEnd);

    if (target.marker === 'start') {
      if (pos < leftMax ) {
        pos = leftMax;
      }
      if (pos > content.cueEnd) {
        pos = content.cueEnd;
      }
      props.handleChangeOperationContent({beepIndex: target.beepIndex, marker:"start"}, pos);
    } else if (target.marker === 'end') {
      if (pos > rightMax) {
        pos = rightMax;
      }
      if (pos < content.cueStart) {
        pos = content.cueStart;
      }
      props.handleChangeOperationContent({beepIndex: target.beepIndex, marker:"end"}, pos);
    }
  }

  const changeBeepDuration = (beepIndex, delta) => {
    const e = 0.00001;
    const currentBeep = content.editInfo.beepList[beepIndex];
    const { start, end } = currentBeep;
    if (end - start > 0.01) {
      props.handleChangeOperationContent({beepIndex: beepIndex, marker:"start"}, start + e * delta);
      props.handleChangeOperationContent({beepIndex: beepIndex, marker:"end"}, end -e * delta);
    }
  };

  let cueStartTime = new Date(source.start_datetime);
  cueStartTime.setSeconds(cueStartTime.getSeconds() + source.duration_ms * content.cueStart / 1000);

  let driverName = car.driver_name;
  let driverPrefix = "";
  let found;
  if (found = driverName.match(/^(\W+)(.*)/)) {
    driverPrefix = found[1];
    driverName = found[2];
  }

  const clickCutAreaButton = (source) => {
    if (content.editInfo.beepList.find(beep => beep.type==='cut')) {
      let beepIndex;
      while ((beepIndex = content.editInfo.beepList.findLastIndex(beep => beep.type==='cut')) !== -1) {
        props.handleDeleteBeep(beepIndex);
      }
    } else {
      props.handleFindCutArea(source);
    }
  }

  return (
    <>
      <article id="modal-operation" className={"modal modal-operation" + (wideOperationModal ? " wide" : "") } onClick={e => { if (e.target.id && e.target.id=='modal-operation') { props.handleClose(); } }}>
        <section className="modal__window">
          <div className="modal__window__inner">
            <div className="modal__close">
              <button className="t-hover" onClick={() => { props.handleClose(); }}><span></span><span></span></button>
            </div>
            <h3 className="headline"><span style={{cursor: wideOperationModal ? 'zoom-out' : 'zoom-in'}} onClick={() => { props.handleChangeWideOperationModal(); }}>CLIP EDIT</span></h3>
            <div className="modal-operation__info">
              <form className="modal-operation__edit">

                <div className={"clip-info"
                  + (car.team_code ? ' team-' + car.team_code.toLowerCase() : '')
                  + (car.engine_code ? ' engine-' + car.engine_code.toLowerCase() : '')
                }>
                  <div className="row">
                    <div className="col">
                      <div className="clip-info__driver d-flex align-items-center"><strong
                        className="num-box"><span>{car.car_no}</span></strong>
                        <figure className="photo"><img src={`../driver-img/${SF_YEAR}/${car.driver_code}.png`} alt=""/></figure>
                        <h4>{driverName.toUpperCase()}</h4></div>
                    </div>

                    <div className="col-auto">
                      <div className="clip-info__name"><span>{getClipSequence(source)}</span></div>
                    </div>

                  </div>
                  <div className="clip-info__before-after">
                    <div className="row gx-4 align-items-center">
                      <div className="col">
                        <div className="block before">
                          <div className="block__info str"><strong>STR</strong><span>{startTimeFormat(source.start_datetime)}</span></div>
                          <div className="block__info dur"><strong>DUR</strong><span>{durationFormat(source.duration_ms)}<small>s</small></span></div>
                          <div className="block__info lap"><strong>LAP</strong><span>{source.lap || "--"}</span></div>
                        </div>
                      </div>
                      { !source.is_finalized && (
                        <>
                      <div className="col-auto">
                        <div className="clip-info__before-after__arrow"></div>
                      </div>
                      <div className="col">
                        <div className="block after">
                          <div className="block__info str"><strong>STR</strong><span>{startTimeFormat(cueStartTime.toTimeString())}</span></div>
                          <div className="block__info dur"><strong>DUR</strong><span>{durationFormat(source.duration_ms * (content.cueEnd - content.cueStart))}<small>s</small></span></div>
                          <div className="block__info lap"><strong>LAP</strong><span>{source.lap || "--"}</span></div>
                        </div>
                      </div>
                        </>)}
                    </div>
                  </div>
                </div>

                <div className="clip-ui">
                  { !source.is_finalized && (
                  <div id="beep-setting" className="clip-ui__bleep"
                     onMouseMove={e => { if (draggingState.target) { if (e.buttons && 1) { moveBeep(e.clientX); } else { clearDraggingState(); } } }}
                  >
                    <div className="base" style={{cursor:"copy"}} onClick={e => { if (!draggingState.target) { addBeep(e.clientX)}; }}>
                      { content.editInfo.beepList && content.editInfo.beepList.map((beep, i) => { return (
                        <div key={i} className={beep?.type === 'cut' ? 'cut' : 'bleep'} style={{cursor:"default", transition:"none", left: beep.start * 100 + "%", width: (beep.end - beep.start) * 100 + "%"}}
                             onClick={e => { e.stopPropagation(); clickBeep(i, beep, e.clientY)}}
                        onWheel = {(e) => { changeBeepDuration(i, e.deltaY) }}
                        >
                        <span className="knob start" draggable={false}
                         onMouseDown={(e) => { startMoveBeep({target: {beepIndex: i, marker:'start'}, startX: e.clientX})}}
                         onMouseMove={e => { if (draggingState.target ) { if (e.buttons && 1) { moveBeep(e.clientX); } else { clearDraggingState(); }}}}
                         onMouseUp={(e) => { setDraggingState({target: null, startX: 0})}}
                        ></span>
                        <span className="delete"
                              onClick={(e) => { e.stopPropagation(); props.handleDeleteBeep(i);  }}
                        ></span>
                        <span className="knob end"
                         onMouseDown={(e) => { startMoveBeep({target: {beepIndex: i, marker:'end'}, startX: e.clientX})}}
                         onMouseMove={e => { if (draggingState.target ) { if (e.buttons && 1) { moveBeep(e.clientX); } else { clearDraggingState(); }}}}
                         onMouseUp={(e) => { setDraggingState({target: null, startX: 0})}}
                        ></span></div>
                      )})
                      }

                    </div>
                  </div>
)}
                  <div className="clip-ui__waveform">
                    <div id="cue" className="base"
                         onMouseMove={e => { if (draggingState.target) { moveCue(e.clientX); }}}
                         onMouseLeave={(e) => { setDraggingState({target: null, startX: 0})}}
                         onMouseUp={(e) => { setDraggingState({target: null, startX: 0})}}
                    >
                      <div id="cue-inner" className="base__inner">
                        <span className="play-bar" style={playStateStyle}></span>
                        { source.is_finalized ? (
                        <img src={`${getEndPoint()}/customclips/${source.customclip_id}/waveform`} alt="" className="waveform" style={{pointerEvents: 'none', userDrag: 'none'}}/>
                        ) : (
                          <>
                        <div className="trim-slider left" style={{transition: 'none', left: (content.cueStart - 1) * 100 + "%"}}

                        ><span
                             onMouseDown={(e) => { startMoveCue({target: 'start', startX: e.clientX})}}
                             onMouseUp={(e) => { setDraggingState({target: null, startX: 0})}}
                        ></span></div>
                        <div className="trim-slider right" style={{transition: 'none', left: (content.cueEnd) * 100 + "%"}}
                        ><span
                             onMouseDown={(e) => { startMoveCue({target: 'end', startX: e.clientX})}}
                             onMouseUp={(e) => { setDraggingState({target: null, startX: 0})}}

                        ></span></div>
                        <img src={
                            source.waveform_object_key ? `${getObjectStorageEndPoint()}/${source.waveform_object_key}` : `${getEndPoint()}/autoclips/${source.autoclip_id}/waveform`} alt="" className="waveform" style={{pointerEvents: 'none', userDrag: 'none'}}/>
                        </>
                        )

                        }
                      </div>
                    </div>
                  </div>
                </div>

                <nav className="modal-operation__button-area">
                  <div className="row g-4">
                    <div className="col">
                      <div className="row g-4">
                        {
                          false && (
                            <div className="col-auto">
                              <button type="button" className="basic-button button t-hover"><span>RESTORE</span></button>
                            </div>
                          )}
                        {!source.is_finalized && (loginUser.is_admin == 1 || loginUser.is_operator == 1) && (
                          <>
                            <div className="col-auto">
                              <button type="button" className="basic-button submit-button t-hover" onClick={() => {
                                props.handleSave();
                              }}><span>SAVE</span>
                              </button>
                            </div>
                            <div className="col-auto">
                              <button type="button" className="basic-button cut-button t-hover"
                                      onClick={() => clickCutAreaButton(source)}><span>{
                                content.editInfo.beepList.find(beep => beep.type === 'cut') ? 'UNSELECT CUT AREA' : 'FIND CUT AREA'
                              }</span></button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    { false && (
                    <div className="col">
                      <div className="row g-4 justify-content-end">
                        <div className="col-auto">
                          {playState && playState.speed == 1 ? (
                            <button type="button" className="basic-button play-button t-hover"
                                    onClick={() => props.handleStopClip(clip_id)}><span>STOP</span></button>

                          ) : (
                            <button type="button" className="basic-button play-button t-hover"
                                    onClick={() => props.handlePlayClipFromBuffer(source, 1, content)}
                                    disabled={!props.clipBufferLoaded}><span>PLAY</span></button>
                          )}

                        </div>

                      </div>
                    </div>
                    )}
                    {true && (
                      <div className="col">
                        <div className="row g-4 justify-content-end">
                          { wideOperationModal && (
                            <div className="col-auto">
                              {playState ? (
                                <button type="button" className="basic-button play-button t-hover"
                                        onClick={() => props.handleStopClip(clip_id)}>
                                  <span>STOP</span></button>

                              ) : (
                                <button type="button" className="basic-button play-button t-hover"
                                        onClick={() => props.handleSmartPlayClip(source, 1, content)}
                                        disabled={!props.clipBufferLoaded}>
                                  <span>SMART PLAY</span></button>
                              )}
                            </div>
                          )}


                          <div className="col-auto">
                            {playState && playState.speed == 1 ? (
                              <button type="button" className="basic-button play-button t-hover"
                                      onClick={() => props.handleStopClip(clip_id)}><span>STOP</span></button>

                            ) : (
                              <button type="button" className="basic-button play-button t-hover"
                                      onClick={() => props.handlePlayClipFromBuffer(source, 1, content)}
                                      disabled={!props.clipBufferLoaded}><span>PLAY</span></button>
                            )}

                          </div>
                          <div className="col-auto">
                            {playState && playState.speed > 1 ? (
                              <button type="button" className="basic-button play-button t-hover"
                                      onClick={() => props.handleStopClip(clip_id)}>
                                <span>STOP</span></button>

                            ) : (
                              <button type="button" className="basic-button play-button t-hover"
                                      onClick={() => props.handlePlayClipFromBuffer(source, 1.5, content)}
                                      disabled={!props.clipBufferLoaded}>
                                <span>×1.5&nbsp;&nbsp;PLAY</span></button>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </nav>


                <div className="modal-operation__form">
                  <div className="input-group">
                    <input type="text" className="form-control sns-title" name="sns-title" placeholder="INPUT TITLE"
                           value={content.title} onChange={e => {
                      props.handleChangeOperationContent('title', e.target.value);
                    }}/>
                    <div className="valid-feedback">ERROR XXXXXXXXXXXXXXXXX</div>
                  </div>
                  <div className="input-group">
                    {clipType === "custom" || source.text_recognized ? (
                      <textarea rows="4" className="form-control" placeholder="INPUT AUDIO-TO-TEXT"
                                value={content.text || source.modified_text} onChange={e => {
                        props.handleChangeOperationContent('text', e.target.value);
                      }}/>
                    ) : (
                      <textarea rows="4" className="form-control" placeholder="INPUT AUDIO-TO-TEXT"
                                value={(source.modified_text || "") + " (...) "} disabled={true}
                                style={{color: 'gray'}}/>
                    )}
                    <div className="valid-feedback">ERROR XXXXXXXXXXXXXXXXX</div>
                  </div>
                </div>

                {(loginUser.is_admin == 1 || loginUser.is_operator == 1) &&
                  <nav className="modal-operation__button-area">
                    <div className="row g-4">
                      <div className="col-auto">
                      <div className="row g-4">
                        { clipType=="custom" && (
                        <div className="col-auto">
                          <button type="button" className="basic-button delete-button t-hover" disabled={source.is_finalized}  onClick={() => { props.handleDelete() }}>
                            <span>DELETE</span></button>
                        </div>
                          )}
                        { false && (
                        <div className="col-auto">
                          <button type="button" className="basic-button sns-button t-hover"><span>SNS POST</span>
                          </button>
                        </div>
                          )}
                      </div>
                    </div>
                    <div className="col">
                      <div className="row g-4 justify-content-end">
                        <div className="col-auto">
                          <div className="row g-4">
                        { false && (
                            <div className="col-auto">
                              <input type="checkbox" className="radio-input sns" id="transmit_sns"
                                     name="transmit_sns" checked={false} onChange={() => {}} /><label htmlFor="transmit_sns"
                                                                className="radio-inline text-nowrap">SNS POST</label>
                            </div>
                            )}
{ !source.is_finalized && (
                            <div className="col">
                              <input type="checkbox" className="radio-input lock" id="transmit_lock"
                                     name="transmit_lock" checked={locked} onChange={e => { props.handleChangeLock(!locked) }} /><label htmlFor="transmit_lock"
                                                                         className="radio-inline">LOCK</label>
                            </div>
  )}
                          </div>
                        </div>
                        <div className="col-auto">
                          <button type="button" className="basic-button transmit-button t-hover" disabled={locked} onClick={() => { props.handleTransmit() }}>
                            <span>TRANSMIT</span></button>
                        </div>
                      </div>
                    </div>

                  </div>
                </nav>
                  }
              </form>
            </div>
          </div>
        </section>
      </article>
    </>
  );
}

export default OperationModal;
