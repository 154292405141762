import React, {useState} from "react";
import Article from "../common/Article";
import {apiPost} from "../common/API";

class NewPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formStatus: {},
      form: {
        password: '',
        confirmPassword: '',
      }
    };
  }

  changeForm(newValues) {
    const form = Object.assign({}, this.state.form);

    Object.keys(form).forEach((k, v) => {
      if (newValues.hasOwnProperty(k)) {
        form[k] = newValues[k];
      }
    })

    this.setState({form: form}, () => {

    });

  }

  changePassword() {
    const {form} = this.state;
    const formStatus = {};

    if (form.password == "") {
      formStatus['password'] = {
        status: 'invalid',
        message: "Please fill new password."
      }
    }
    if (form.confirmPassword == "") {
      formStatus['confirmPassword'] = {
        status: 'invalid',
        message: "Please fill new password again."
      }
    } else if (form.password !== form.confirmPassword) {
      formStatus['confirmPassword'] = {
        status: 'invalid',
        message: "Two passwords are not the same."
      }
    }

    if (formStatus.password || formStatus.confirmPassword) {
      this.setState({formStatus: formStatus});
      return false;
    }
    const password = {
      password: form.password,
    };
    apiPost("/change_password", password).then(res => {
      console.log(res);
      if (res.data.result) {
        this.setState({
          mode: 'completeChangePassword',
          form: {
            password: '',
            confirmPassword: '',
          },
          formStatus:{},
        });
        this.props.handleComplete();
      }

    });

  }


  clearFormError() {
      this.setState({formStatus: {}});
  }

  render() {
    const { form, formStatus } = this.state;
    return (
      <>
        <div className="login">
          <Article className="content" {...this.props}>


            <form className="login__form" onSubmit={this.props.handleSubmit}>

              <div className="notice">
                <h3>Reset your password</h3>
                <p>Enter your new password and finalize by pressing the Reset Password button.</p>
              </div>

              <div className="login__form__inner">

                <div className={"input-group" + ((formStatus.password && formStatus.password.status=="invalid") ? " invalid-feedback" : "")}>
                  <label htmlFor="new-password" className="form-label">NEW PASSWORD</label>
                  <input autoFocus type="password" id="new-password" className="form-control" placeholder="INPUT NEW PASSWORD"
                     value={form.password}
                     onClick={() => { this.clearFormError() }}
                     onChange={e => {this.changeForm({password: e.target.value})}}
                  />
                  <div className="valid-feedback">{formStatus.password && formStatus.password.message.toUpperCase()}</div>
                </div>
                <div className={"input-group" + ((formStatus.confirmPassword && formStatus.confirmPassword.status=="invalid") ? " invalid-feedback" : "")}>
                  <label htmlFor="confirm-new-password" className="form-label">CONFIRM NEW PASSWORD</label>
                  <input type="password" id="confirm-new-password" className="form-control"
                         placeholder="RE-INPUT NEW PASSWORD"
                                       value={form.confirmPassword}
                     onClick={() => { this.clearFormError() }}
                     onChange={e => {this.changeForm({confirmPassword: e.target.value})}}
                  />
                  <div className="valid-feedback">{formStatus.confirmPassword && formStatus.confirmPassword.message.toUpperCase()}</div>
                </div>
                <div className="button-area">
                  <div className="row">
                    {this.props.loginUser ? (
                      <>
                        <div className="col">
                          <button type="button" className="basic-button button t-hover"
                                  onClick={this.props.handleCancel}><span>CANCEL</span>
                          </button>
                        </div>
                        <div className="col">
                          <button type="button" className="basic-button submit-button login__button t-hover" onClick={() => {this.changePassword();}}>
                            <span>CHANGE PASSWORD</span></button>
                        </div>
                      </>
                    ) : (
                      <div className="col-6">
                        <button type="button" className="basic-button submit-button login__button t-hover"><span>RESET PASSWORD</span>
                        </button>
                      </div>
                    )
                    }
                  </div>

                </div>

              </div>

            </form>

          </Article>
        </div>
      </>
    );
  }
}

export default NewPassword;
