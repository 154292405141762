import React from "react";
import Article from "../common/Article";

const ForgotPassword = (props) => {

  return (
    <>
      <div className="login">
      <Article className="content" login={props.login}>


        <form className="login__form">

          <div className="notice">
            <h3>Forgot password</h3>
            <p>Please enter your email address(ID) so we can send you an email to reset your password.</p>
          </div>

          <div className="login__form__inner">

            <div className="input-group">
              <label htmlFor="email-address" className="form-label">EMAIL ADDRESS</label>
              <input type="text" id="email-address" className="form-control" placeholder="INPUT EMAIL ADDRESS" />
                <div className="valid-feedback">ERROR INVALID EMAIL ADDRESS.</div>
            </div>
            <div className="button-area">
            <div className="row">
            <div className="col"><button type="reset" className="basic-button button login__button t-hover" onClick={props.handleLoginForm}><span>BACK</span></button></div>
            <div className="col"><button type="submit" className="basic-button submit-button login__button t-hover"><span>SUBMIT</span></button></div>
            </div>
            </div>
          </div>

        </form>

      </Article>
      </div>
    </>
  );
}

export default ForgotPassword;
