/*
              <div class="button-area">
              <div class="row justify-content-center">
              <div class="col-auto"><button type="submit" class="basic-button submit-button t-hover"><span>XXXXXX</span></button></div>
              </div>
              </div>

 */
import React from "react";
import Article from "../common/Article";

const MfaForm = (props) => {

  return (
    <>
      <div className="login">
      <Article className="content" login={props.login}>


        <form className="login__form" onSubmit={props.handleLogin}>

          <ul className="flow">
            <li><strong className="active"><span>1</span></strong></li>
            <li><strong className="active"><span>2</span></strong></li>
          </ul>

          <div className="notice">
            <h3>A verification code has been sent to your email address (user id).</h3>
            <p>Pleaes type this code in the under input box.</p>
          </div>

          <div className="login__form__inner">

             <div className={"input-group" + ((props.formStatus.mfaToken && props.formStatus.mfaToken.status=="invalid") ? " invalid-feedback" : "")}>
              <label htmlFor="verification-code" className="form-label">VERIFICATION CODE</label>
              <input autoFocus type="password" id="verification-code" className="form-control" maxLength={6}
                     placeholder="INPUT VERIFICATION CODE"
                       value={props.form.mfaToken}
                     onClick={props.handleClearError}
                     onChange={e => {props.handleChange({mfaToken: e.target.value})}}/>
                <div className="valid-feedback">{props.formStatus.mfaToken && props.formStatus.mfaToken.message.toUpperCase()}</div>
            </div>
            <div className="button-area">
              <div className="row">
                <div className="col-6">
                  <button type="submit" className="basic-button submit-button login__button t-hover" onClick={props.handleLogin}><span>LOGIN</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="forgot-password">
              Do you miss your verification code?<br />
              <a className="t-hover" onClick={props.handleSendToken}>Send the verification code again
              </a>
            </div>

          </div>

        </form>

      </Article>
      </div>
    </>
  );
}

export default MfaForm;
