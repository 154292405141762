import React from "react";
import Article from "../common/Article";

const CommonModal = (props) => {

  return (
    <>
      <article className="modal modal-alert">
        <section className="modal__window">
          <div className="modal__window__inner">
            <div className="modal__close">
              <button className="t-hover" onClick={props.handleClose}><span></span><span></span></button>
            </div>
            <h3 className="headline">XXXXXXXXXXXXXXX?</h3>
            <div className="alert-info">
              <p>このモーダルウィンドウは、汎用で使用できます。</p>
              <nav className="alert-info__button-area">
                <div className="row g-4 justify-content-center">
                  <div className="col-auto">
                    <button type="button" className="basic-button button t-hover" onClick={props.handleClose}><span>CANCEL</span></button>
                  </div>
                  <div className="col-auto">
                    <button type="submit" className="basic-button submit-button t-hover"><span>OK</span></button>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </section>
      </article>
    </>
  );
}

export default CommonModal;
