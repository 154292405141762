import React from "react";
import ClipBlock from "./ClipBlock";
import { SF_YEAR, getDriverNameRows } from "../../../lib/util";

const HotArea = (props) => {
  const { car, loginUser } = props;

  return (
    <div className="driver-wrap__hot-area">
      <button className="driver-wrap__tag" onClick={() => { props.handleClickDriver(car); }}>
        <dl>
          { (car.last_process && car.last_process?.status.match(/voice/) &&
            (car.last_process.clip_start_sample > car.maxClipStartSample)) && (
          <dt className="coming-clip"><p className="coming-clip__info">
            <strong>{car.driver_code}</strong><span>{Number.parseFloat(car.last_process?.voice_sec || 0).toFixed(0)} <small>s</small></span></p></dt>
            )
          }


          <dt><strong className="num-box"><span>{car.car_no}</span></strong>
            <figure className="photo"><img src={`../driver-img/${SF_YEAR}/${car.driver_code}.png`} alt=""/></figure>
          </dt>
          <dd><h4
            className={"driver " + (car.driver_name.match(/^\W+/) ? "special-name" : "normal-name") + (" status-" + (car.last_process?.status || "unknown"))}>{getDriverNameRows(car.driver_name)}
          </h4>
          </dd>
        </dl>
      </button>
      <div className="block-area">
        {car.hotClips.map((hotClip, i) => {
          return hotClip ?
          <ClipBlock {...props} isHot={hotClip.isHot} clip={hotClip ? hotClip.clip : null} key={hotClip ? hotClip.clip.autoclip_id : "null-" + i} />
          : <ClipBlock {...props} clip={null} key={"null-" + i}/>
        })}
      </div>
    </div>
  )
};
export default HotArea;
