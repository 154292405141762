import React, {useEffect, useRef, useState} from "react";
import Article from "../common/Article";
import {format} from "date-fns";
import CustomClip from "./sub/CustomClip";
import HotArea from "./sub/HotArea";
import HistoryArea from "./sub/HistroryArea";
import LockOnArea from "./sub/LockOnArea";

const END_POINT = (window.location.hostname === "localhost") ? 'http://localhost:8000/admin' : '/admin';

const BlockOperation = (props) => {
  const t = props.timeline.current;
  const { lockOnList } = props;
  if (!t.cars) {
    return null;
  }
  const car = t.cars.find(car => (car.car_no == props.carNo));
  let teamCode = '', bgAlternate = true;
  return (
    <>
      <div className="livegraphics authentication">
      <Article className="content" {...props}>
        <div className="livegraphics__ui">
          <div className="livegraphics__header d-flex align-items-center justify-content-between">
            <div className="left-side">
              <div className="row gx-4">
                <div className="col-auto">
                  <button className="back-button t-hover" onClick={props.handleBack}><span>BACK</span></button>
                </div>
                <div className="col-auto">
                  <div className="mini-title"><strong><span>ROUND</span></strong><span className="mini-title__title">{t.year} ROUND {t.round_id} - {t.section_name.toUpperCase()}</span>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="mini-title"><strong><span>TIME LINE</span></strong><span
                    className="mini-title__title">{t.title}</span></div>
                </div>
              </div>
            </div>
            <div className="right-side">
              <div className="row gx-1">
                {false && (
                  <>
                    <div className="col-auto">
                      <button className="mini-tab on" id="car-order"><strong><span>CAR ORDER</span></strong></button>
                    </div>
                    <div className="col-auto">
                      <button className="mini-tab ranking" id="ranking"><strong><span>RANKING</span></strong></button>
                    </div>
                  </>
                )}
                <div className="col-auto">
                  <button className={"mini-tab" + (props.autoSilenceDetection ? ' on' : '')} id="silence-detection"
                          onClick={() => {
                            props.handleChangeSilenceDetectionMode()
                          }}>
                    <strong><span>SILENCE DETECTION</span></strong></button>
                </div>

                <div className="col-auto">
                  <button className="mini-tab on" id="b-mode" onClick={() => {
                    props.handleChangeViewMode('block');
                  }}><strong><span>BLOCK VIEW</span></strong></button>
                </div>
                {true && (
                  <div className="col-auto">
                    <button className="mini-tab" id="t-mode" onClick={() => {
                      props.handleChangeViewMode('timeline');
                    }}><strong><span>TIME-LINE VIEW</span></strong></button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <CustomClip {...props} />
        {lockOnList.length > 0 && (
          <LockOnArea {...props} />
        )}
        <div className="block-view">
          {
            props.block && props.block.map((car, i) => {
              if (teamCode !== car.team_code) {
                bgAlternate = !bgAlternate;
                teamCode = car.team_code;
              }
              return (

                <div className={"driver-wrap"
                  + (" car-" + car.car_no)
                  + ((car.capture_status=='terminated' && !props.virtualCurrentTime) ? " terminate" : "")
                  + (lockOnList.find(c => car.car_no === c.car_no) ? " locked-on" : "")
                  + (car.team_code ? ' team-' + car.team_code.toLowerCase() : '')
                  + (car.engine_code ? ' engine-' + car.engine_code.toLowerCase() : '')
                  + (bgAlternate ? ' bg-alternate' : '')
                } key={i}>
                  <HotArea {...props} car={car}/>
                  <HistoryArea {...props} car={car}/>
                </div>

            )})
          }
        </div>

      </Article>
      </div>
    </>
  );
}

export default BlockOperation;
