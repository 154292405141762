// import "bootstrap/dist/css/bootstrap.min.css";
import './style.css';
import React, {useState, useEffect} from "react";
import TimelineList from "./components/TimelineList";
import fileDownload from 'js-file-download';
import axios from "axios";
import {apiCall} from './lib/api'
import {Button, Container} from "react-bootstrap";
import TimelineDetail from "./components/TimelineDetail";
import ClipList from "./components/ClipList";
import LoginController from "./components/login/LoginController";
import MainMenu from "./components/navigation/MainMenu";
import ArchiveController from "./components/archive/ArchiveController";
import MonitoringController from "./components/monitoring/MonitoringController";
import SettingController from "./components/setting/SettingController";
import DemoSettingController from "./components/demo/DemoSettingController";
import CommonModal from "./components/common/CommonModal";
import AccountModal from "./components/common/AccountModal";
import Logout from "./components/login/Logout";
import NewPassword from "./components/login/NewPassword";
import {apiGet, apiPost, apiPut, apiDelete} from "./components/common/API";
import RequestComplete from "./components/login/RequestComplete";
import OperationController from "./components/operation/OperationController";
import {getWebSocketEndPoint, startTimeFormat} from "./lib/util";
import ReconnectingWebSocket from "reconnecting-websocket";
import { SF_YEAR } from "./lib/util";

const END_POINT = (window.location.hostname === "localhost") ? 'http://localhost:8000/admin' : '/admin';
const XHR_CONFIG = { headers: { 'x-device-authentication': 'sf-teamradio'},  withCredentials: true};
const POLLING_INTERVAL = 5000;
const OPERATOR_POLLING_INTERVAL = 3000;

const audioCtx = new AudioContext();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeline: {
        list: [],
        currentSelectId: 0,
        currentId: 0,
        current: {},
      },
      myCustomClips: [],
      pollingTimerId: null,
      view: 'timeline',
      screenName: 'login',
      screenMenu: '',
      demoSeconds: 0,
      overlayScreenMenu: '',
      modalType: '',
      clip: {
        source: null
      },
      editClip: null,
      operationClip: null,
      loginUser: null,
      initialized: false,
      loading: true,
      screenChangeTime: null,
      webSocket: null,
    };
  }

  componentDidMount() {
    apiGet('/current_user').then(res => {
      if (res.data) {
        this.login(res.data);
      }

    }).finally(() => {
      this.loading(false);
      this.setState({initialized: true});
    })
  }

  componentWillUnmount() {
    this.clearPolling();
    this.closeWebSocket();
  }

  connectWebSocket() {
    if (this.state.webSocket) {
      this.closeWebSocket(true);
    } else {
      this.openWebSocket();
    }
  }

  openWebSocket() {
    if (!this.state.webSocket) {
      const ws = new ReconnectingWebSocket(getWebSocketEndPoint() + "/websocket/realtime");
      ws.addEventListener('message', e => this.handleWebSocketMessage(e))
      this.setState({webSocket: ws});
    }
  }

  closeWebSocket(reopen = false) {
    const ws = this.state.webSocket;
    console.log("ws closing check");
    if (ws) {
      console.log("ws closed");
      ws.close();
      ws.removeEventListener('message', this.handleWebSocketMessage);
    }
    this.setState({webSocket: null}, () => { reopen && this.openWebSocket(); })
  }

  handleWebSocketMessage(event) {
    const data = JSON.parse(event.data)
    console.log(data);
    switch (data.command) {
      case 'new_autoclips':
        this.mergeAutoclips(data.autoclips);
        break;
      case 'connected':
        console.log('WS Connected');
        break;

    }
  }

  updateTimelineList() {
    axios.get(END_POINT + "/timelines/year/" + SF_YEAR, XHR_CONFIG).then(res => {
      const list = res.data;
      const timeline = {list: list};
      if (this.state.timeline.currentSelectId === 0) {
        timeline.currentSelectId = list[0].timeline_id;
      }
      this.setTimelineState(timeline);
    });
  }

  clearPolling() {
    if (this.state.pollingTimerId) {
      console.log("Polling stopped.")
      clearTimeout(this.state.pollingTimerId);
    }
  }

  setTimelineState(obj, callback = () => {}) {
    const n = {timeline: { ...this.state.timeline, ...obj }};
    this.setState(n, callback);
  }

  createTimeline(round, section, title) {
    console.log('create');
    this.clearPolling();
    const data = {year: SF_YEAR, round_id: round, section_name: section, title: title};
    axios.post(END_POINT + "/timelines", data, XHR_CONFIG).then(res => {
      const timelineId = res.data.timeline_id
      this.updateTimelineList();
      this.setTimelineState({currentSelectId: timelineId});
      this.changeScreen("monitoring", timelineId)
    }).catch(error => {
      console.log(error);
    });
  }

  changeTimelineSelect(timelineId, callback = () => {}) {
    console.log(timelineId);
    this.setTimelineState({currentSelectId: timelineId}, callback);
  }

  switchTimeline() {
    this.clearPolling();
    this.setTimelineState({currentId: this.state.timeline.currentSelectId}, () => { this.updateTimelineDetail(); });
  }

  changeView(view) {
    this.setState({view: view});
  }

  updateTimelineDetail() {
    const screenMenu = this.state.screenMenu;
    axios.get(END_POINT + "/timelines/" + this.state.timeline.currentId + (screenMenu === 'operation' ? '/operation' : ''), XHR_CONFIG).then(res => {
      this.setTimelineState({current: res.data});
      if (res.data.capture_status === 'active') {
        console.log("Active timeline -> Polling start");

        if (this.state.operationClip) {

          const operationClip = Object.assign(this.state.operationClip);
          // console.log(operationClip);
          if (operationClip.clipType == "auto") {
            const tl = res.data;
          // tbd
            const car = tl.cars.find(car => car.car_no == operationClip.source.car_no);
            const clip = car.clips.find(clip => (clip.autoclip_id == operationClip.source.autoclip_id));
            if (clip) {
              operationClip.source = clip;
              // console.log("update clip");
              this.setState({operationClip: operationClip});
            }

          }
        }
        this.clearPolling();
        this.setState({pollingTimerId: setTimeout(() => {this.updateTimelineDetail();},
            this.state.loginUser.is_operator ? OPERATOR_POLLING_INTERVAL : POLLING_INTERVAL)})

      } else {
        console.log("Passive timeline -> no polling");
        if (screenMenu === 'operation') {
          this.updateTimelineList();
          this.backToMenu();
        }
      }

    }).catch(error => {
      console.log(error);
      console.log("Polling stopped")
      this.clearPolling();
      this.setState({pollingTimerId: setTimeout(() => {this.updateTimelineDetail();},
        this.state.loginUser.is_operator ? OPERATOR_POLLING_INTERVAL : POLLING_INTERVAL)})
    });
  }

  terminateTimelineCar(car_no) {
    if (window.confirm('Are you sure to terminate car number ' + car_no + ' ?')) {
      axios.put(END_POINT + "/timelines/terminate/" + car_no, {}, XHR_CONFIG).then(res => {
        // this.updateTimelineDetail()
      });
    }
  }

  resetCurrentTimeline() {
    console.log('aaa');
    axios.put(END_POINT + "/timelines/reset", {}, XHR_CONFIG).then(res => {
    });
  }

  activateCurrentTimeline() {
    const activeTimeline = this.state.timeline.list.find(item => (item.capture_status==='active'));
    if (activeTimeline) {
      alert('Other activated timeline exists. Terminate it first.');
      return false;
    }
    if (window.confirm('Are you sure to activate the timeline ?')) {
      this.clearPolling();
      axios.put(END_POINT + "/timelines/activate/" + this.state.timeline.currentId, {}, XHR_CONFIG).then(res => {
        this.setTimelineState({current: res.data});
        this.updateTimelineList();
        this.updateTimelineDetail();
      });
    }
  }

  terminateCurrentTimeline() {
    const activeTimeline = this.state.timeline.list.find(item => (item.capture_status==='active'));
    if (!activeTimeline) {
      alert('No active timeline');
      return false;
    }
    if (window.confirm('Are you sure to terminate the timeline ?')) {
      this.clearPolling();
      axios.put(END_POINT + "/timelines/terminate", {}, XHR_CONFIG).then(res => {
        this.setTimelineState({current: res.data});
        this.updateTimelineList();
      });
    }
  }

  mergeAutoclips(autoclips) {
    const tl = this.state.timeline.current;
    autoclips.forEach(autoclip => {
      if (tl.cars) {

        const cars = tl.cars.slice();
        const carIndex = cars.findIndex(car => car.car_no == autoclip.car_no);
        const clipList = cars[carIndex].clips.slice();

        if (!clipList.find(clip => clip.autoclip_id == autoclip.autoclip_id)) {
          const newClipList = [autoclip].concat(clipList);
          cars[carIndex] = Object.assign({}, cars[carIndex], {clips: newClipList});
          const newTl = Object.assign({}, tl, {
            cars: cars
          });

          this.setTimelineState({current: newTl});
        }
      }
    })
  }

  updateCarAutoclipList(car_no, clipList) {
    const tl = this.state.timeline.current;
    const cars = tl.cars.slice();

    const carIndex = tl.cars.findIndex(car => car.car_no === car_no);
    cars[carIndex] = Object.assign({}, cars[carIndex], {clips: clipList});

    const new_tl = Object.assign({}, tl, {
      cars: cars
    });
    this.setTimelineState({current: new_tl});
  }

  retreiveCarAutoclips(car_no) {
    const tl = this.state.timeline.current;
    axios.get(END_POINT + "/timelines/" + tl.timeline_id + "/cars/" + car_no + "/autoclips", XHR_CONFIG).then(res => {
      this.updateCarAutoclipList(car_no, res.data);
    });
  }

  requestAudioFeature(clip_id, callback) {
    if (Number.isInteger(clip_id)) {
     axios.get(END_POINT + `/autoclips/${clip_id}/audio_feature`,
       Object.assign({}, XHR_CONFIG, {})).then(res => {
        callback && callback(res);
     });
    }
  }

  requestClip(clip_id, callback, isSmart = false) {
    let url;
    if (Number.isInteger(clip_id)) {
      url = "/autoclips/";
    } else {
      url = "/customclips/";
      clip_id = clip_id.substring(1)
    }
    if (isSmart) {
      url += "smart/";
    }
    axios.get(END_POINT + url + clip_id,
      Object.assign({}, XHR_CONFIG, { responseType : 'arraybuffer' })).then(res => {
      callback && callback(res);
    });
  }

  downloadClip(clip) {
    const clip_id = clip.is_finalized ? ('c' + clip.customclip_id) : clip.autoclip_id;
    this.requestClip(clip_id, res => {
      const filename = clip.object_key || clip.filename;
      fileDownload(res.data, filename.replace(/^.+[\/\\]/, ""));
    });
  }

  transmitClip(clip) {
    console.log(clip);
     axios.post(END_POINT + "/autoclips/transmit/" + clip.autoclip_id, {},
       XHR_CONFIG).then(res => {

     });
  }

  directTransmit(clip, defaultContent) {
    const t = this.state.timeline.current;
    const car = t.cars.find(car => car.car_no === clip.car_no);
    console.log(clip);
    const content = Object.assign({
      title: `${t.year} ROUND ${t.round_id} (${t.section_name.toUpperCase()}) ${t.title} CAR#${car.car_no} ${startTimeFormat(clip.start_datetime)}`,
      text: clip.modified_text,
      cueStart: clip.cueStart,
      cueEnd: clip.cueEnd,
    }, defaultContent);
    const operationClip = {
      clipType: 'auto',
      source: clip,
      car: car,
      locked: true,
      content: content,
    }
    this.setState({operationClip: operationClip}, () => { this.transmitCustomClip()});
  }

  transmitCustomClip() {
    this.saveCustomClip(true);
  }

  serializeEditInfo(editInfo) {
    let command = [];
    if (editInfo.beepList.length) {
      command.push(editInfo.beepList.map(beep => ((beep.type === "cut" ? 'C' : 'B') + (beep.start.toFixed(6) + '-' + beep.end.toFixed(6)))));
    }
    return command.flat().join(",");
  }

  unserializeEditInfo(str) {
    const beepList = [];
    let command = str.split(",");
    command.forEach(c => {
      const type = c.substring(0, 1);
      const operand = c.substring(1);
      let val;
      switch(type) {
        case 'B':
          val = operand.split('-');
          beepList.push({type: 'beep', start: parseFloat(val[0]), end: parseFloat(val[1])});
          break;
        case 'C':
          val = operand.split('-');
          beepList.push({type: 'cut', start: parseFloat(val[0]), end: parseFloat(val[1])});
          break;      }
    });
    return {
      beepList : beepList
    };
  }

  saveCustomClip(transmit = false) {
    const clip = this.state.operationClip;
    const data = {
      customclip_title: clip.content.title,
      modified_text: clip.content.text || clip.source.modified_text,
      cue_start: clip.content.cueStart,
      cue_end: clip.content.cueEnd,
      edit_info: this.serializeEditInfo(clip.content.editInfo),
      transmit: transmit,
    }

    if (clip.source.hasOwnProperty('customclip_id')) {
      apiPut('/customclips/' + clip.source.customclip_id, data).then(res => {
        this.loadMyCustomClips(res.data.timeline_id)
      })
    } else {
      data.autoclip_id =clip.source.autoclip_id;
      apiPost('/customclips', data).then(res => {
        this.loadMyCustomClips(res.data.timeline_id)
      })
    }
    this.closeModal()
  }

  deleteCustomClip() {
    const clip = this.state.operationClip;
    apiDelete('/customclips/' + clip.source.customclip_id).then(res => {
      this.loadMyCustomClips(this.state.timeline.current.timeline_id)
    })
    this.closeModal()
  }

  loadMyCustomClips(timelineId) {
    if (timelineId) {
      apiGet('/timelines/' + timelineId + '/mycustomclips').then(res => {
        this.setState({myCustomClips: res.data});
      })
    }
  }

  loadAllCustomClips() {
    apiGet('/customclips/all').then(res => {
      console.log(res);
//      this.setState({});
    })
  }


  playClip(clip, speed = 1) {
    const clip_id = clip.is_finalized ? ('c' + clip.customclip_id) : clip.autoclip_id;
    this.state.clip.source && this.state.clip.source.stop()

    this.requestClip(clip_id, res => {
      audioCtx.decodeAudioData(
        res.data,
        (buffer) => {
          const source = audioCtx.createBufferSource();
          source.buffer = buffer;
          source.connect(audioCtx.destination);
          source.start(0);
          this.setState({clip: {source: source}})
        },
        (error) => {
            console.error('decodeAudioData error', error);
        }
      );
    });
  }

  editClipText(clip) {
    this.setState({editClip: clip});
    this.openModal("editTextModal");
  }

  editOperation(clip, defaultContent = {}) {
    const t = this.state.timeline.current;
    const car = t.cars.find(car => car.car_no === clip.car_no);
    const content = Object.assign({}, {
      title: `${t.year} ROUND ${t.round_id} (${t.section_name.toUpperCase()}) ${t.title} CAR#${car.car_no} ${startTimeFormat(clip.start_datetime)}`,
      cueStart: 0,
      cueEnd: 1,
      text: '',
      editInfo: {beepList: []},
    }, defaultContent);
    if (clip.customclip_id && !clip.is_finalized) {
      content.cueStart = clip.cue_start;
      content.cueEnd = clip.cue_end;
      content.editInfo = this.unserializeEditInfo(clip.edit_info);
    }
    const clipType = clip.customclip_id ? "custom" : "auto";
    if (clipType === "custom") {
      content.title = clip.customclip_title;
      content.text = clip.modified_text;
    }
    const operationClip = {
      clipType: clipType,
      source: clip,
      car: car,
      locked: true,
      content: content,
    }

    this.setState({operationClip: operationClip});
    this.openModal("operationModal");
  }

  changeOperationContent(target, value) {
    const operationClip = Object.assign({}, this.state.operationClip);
    if (target.hasOwnProperty("beepIndex")) {
      const editInfo = Object.assign({}, operationClip.content.editInfo);
      const beepList = editInfo.beepList;
      beepList[target.beepIndex][target.marker] = value;
      editInfo.beepList = beepList;
      operationClip.content.editInfo = editInfo;
    } else {
      operationClip.content[target] = value;
    }
    this.setState({operationClip: operationClip});

  }

  addBeep(pos, type='beep') {
    const operationClip = Object.assign({}, this.state.operationClip);
    const editInfo = Object.assign({}, operationClip.content.editInfo);
    const beepList = editInfo.beepList;
    let start, end;
    if (pos.start && pos.end) {
      start = pos.start;
      end = pos.end;
    } else {
      start = pos - 0.05;
      end = pos + 0.05;
    }
    start = Math.max(start, 0);
    end = Math.min(end, 1);
    start = beepList.filter(beep => beep.end <= end).reduce((startPos, beep) => Math.max(startPos, beep.end), start);
    end = beepList.filter(beep => beep.start >= start).reduce((endPos, beep) => Math.min(endPos, beep.start), end);
    if (Math.abs(end - start) < 0.01) {
      return false;
    }
    if (!isNaN(start)) {
      beepList.push({start: start, end: end, type: type});
    }
    this.setState({operationClip: { ...operationClip, editInfo: { ...editInfo, beepList: beepList.sort((a, b) => b.start - a.start)}}});
  }

  deleteBeep(beepIndex) {
    const operationClip = Object.assign({}, this.state.operationClip);
    const editInfo = Object.assign({}, operationClip.content.editInfo);
    const beepList = editInfo.beepList;
    beepList.splice(beepIndex, 1);
    this.setState({operationClip: { ...operationClip, editInfo: { ...editInfo, beepList: beepList }}});
  }

  changeOperationLock(val) {
    const operationClip = this.state.operationClip;
    operationClip.locked = val;
    console.log(val);
    this.setState({operationClip: operationClip});
  }

  changeOpenStatus(clip, isOpen) {
    apiPut('/autoclips/' + clip.autoclip_id + '/' + (isOpen ? 'show' : 'hide')).then(res => {
      this.updateTimelineDetail();
    });
  }

  changeScreen(screenMenu, timelineId) {
    this.clearPolling();
    const state = {screenMenu: screenMenu, demoSeconds: 0, overlayScreenMenu: '', screenChangeTime: new Date()};
    if (timelineId) {
      this.loading(true);
      this.changeTimelineSelect(timelineId, () => {
        this.switchTimeline();
        this.loading(false);
      });
    }
    this.setState(state, () => {
      if (screenMenu == 'operation') {
        this.loadMyCustomClips(timelineId);
        if (this.state.loginUser.is_operator) {
          this.connectWebSocket();
        }
      } else {
        if (this.state.loginUser?.is_operator) {
          this.closeWebSocket();
        }
      }
    });
  }

  startDemo(timelineId, goBackMinutes = 10) {
    this.clearPolling();
    const state = {screenMenu: 'operation-demo', demoSeconds: goBackMinutes * 60, overlayScreenMenu: '', screenChangeTime: new Date()};
      this.changeTimelineSelect(timelineId, () => {
      this.switchTimeline();
      this.setState(state);
      this.loadMyCustomClips(timelineId);
    });
  }

  changeOverlayScreen(screenMenu) {
    this.setState({overlayScreenMenu: screenMenu});
  }

  login(user) {
    this.setState({loginUser: user, screenMenu: 'menu'});
    this.updateTimelineList();
  }

  logout(user) {
    this.closeModal()
    apiPost('/logout', {}).then(res => {
      this.setState({loginUser: null, screenMenu: 'logout', overlayScreenMenu: ''});
    })
  }

  changePassword() {
    this.closeModal()
    this.setState({overlayScreenMenu: 'changePassword'});
  }

  cancelChangePassword() {
    this.closeModal()
    this.setState({overlayScreenMenu: ''});
  }

  changePasswordComplete() {
    this.closeModal()
    this.setState({overlayScreenMenu: 'changePasswordComplete'});
  }

  backToMenu() {
    this.clearPolling();
    this.closeWebSocket();
    this.setState({screenMenu: 'menu'});
  }

  openModal(modalType) {
    this.setState({modalType: modalType});
  }

  closeModal() {
    this.setState({modalType: ''});
  }

  loading(flag = true) {
    this.setState({loading: flag});
  }

  render() {
    const {initialized, loginUser, modalType} = this.state;

    if (!initialized) {
      return this.state.loading && (
          <div className="loading d-flex align-items-center justify-content-center">
            <div className="loading__inner">
              <div className="loading__unit"></div>
            </div>
          </div>
      )
    }
    if (loginUser && loginUser.login_id) {
      const headerAccount = (
              <div className="header__account"><a className="t-hover" onClick={() => { this.openModal('accountModal'); }}>
                <span>Hello!</span><u className="under-bar">{loginUser.username}</u></a>
              </div>
            );
      let mainComponent;
      switch (this.state.screenMenu) {
        case 'menu':
          mainComponent = <MainMenu
            timeline={this.state.timeline}
            loginUser={this.state.loginUser}
            pageClass={"top"}
            handleChangeScreen={(screenName, timelineId) => { this.changeScreen(screenName, timelineId); }}
            headerAccount={headerAccount}
          />;
          break;
        case 'archive':
          mainComponent = <ArchiveController
            timeline={this.state.timeline}
            loginUser={this.state.loginUser}
            editClip={this.state.editClip}
            headerAccount={headerAccount}

            backToMenu={() => this.backToMenu()}
            handleAccountModalOpen={() => this.openModal('accountModal')}
            handleAccountModalClose={() => this.closeModal()}
            handleChangeScreen={(screenName, timelineId) => { this.changeScreen(screenName, timelineId); }}
            handlePlay={clip => {
              this.playClip(clip)
            }}
            handleDownload={clip => {
              this.downloadClip(clip)
            }}
            handleTransmit={clip => {
              this.transmitClip(clip)
            }}

            handleEditText={clip => {
              this.editClipText(clip)
            }}
            handleClose={() => { this.closeModal() }}
            handleLoading={flag => { this.loading(flag); }}
          />
          break;
        case 'monitoring':
          mainComponent = <MonitoringController
            timeline={this.state.timeline}
            loginUser={this.state.loginUser}
            headerAccount={headerAccount}

            backToMenu={() => this.backToMenu()}
            handleAccountModalOpen={() => this.openModal('accountModal')}
            handleAccountModalClose={() => this.closeModal()}

            handleActivate={() => { this.activateCurrentTimeline() }}
            handleTerminateCar={carNo => { this.terminateTimelineCar(carNo) }}
            handleTerminate={() => { this.terminateCurrentTimeline() }}

            handlePlay={clip => {
              this.playClip(clip)
            }}
            handleDownload={clip => {
              this.downloadClip(clip)
            }}
            handleTransmit={clip => {
              this.transmitClip(clip)
            }}
            handleOpenStatusChange={(clip, isOpen) => {
              this.changeOpenStatus(clip, isOpen);
            }}
            handleGetAutoclips={car_no => { this.retreiveCarAutoclips(car_no) }}

          />
          break;
        case 'operation': case 'operation-demo':
          mainComponent = <OperationController
            timeline={this.state.timeline}
            loginUser={this.state.loginUser}

            screenChangeTime={this.state.screenChangeTime}
            demoSeconds={this.state.screenMenu === 'operation-demo' ? this.state.demoSeconds : 0}

            headerAccount={headerAccount}
            operationClip={this.state.operationClip}
            myCustomClips={this.state.myCustomClips}
            backToMenu={() => this.backToMenu()}
            handleAccountModalOpen={() => this.openModal('accountModal')}
            handleAccountModalClose={() => this.closeModal()}

            handlePlay={clip => {
              this.playClip(clip)
            }}
            handleEditOperation={(clip, content = null) => {
              this.editOperation(clip, content)
            }}
            handleChangeLock={val => { this.changeOperationLock(val); }}

            handleChangeOperationContent={(target, str) => { this.changeOperationContent(target, str); }}
            handleAddBeep={(pos, type='beep') => {this.addBeep(pos, type);}}
            handleDeleteBeep={index => {this.deleteBeep(index);}}


            handleDirectTransmit={(clip, content) => {this.directTransmit(clip, content);}}

            handleTransmit={(clip, content) => {
              this.transmitCustomClip(clip, content)
            }}
            handleSaveCustomClip={() => { this.saveCustomClip(); }}
            handleDeleteCustomClip={() => { this.deleteCustomClip(); }}

            handleClose={() => { this.closeModal() }}
            handleRequestClip={(clip_id, res, isSmart = false) => { this.requestClip(clip_id, res, isSmart); }}
            handleRequestAudioFeature={(clip_id, res) => { this.requestAudioFeature(clip_id, res); }}

          />
          break;
        case 'setting':
          mainComponent = <SettingController
            timeline={this.state.timeline}
            loginUser={this.state.loginUser}
            handleChangeScreen={(screenName, timelineId) => { this.changeScreen(screenName, timelineId); }}
            headerAccount={headerAccount}
            backToMenu={() => this.backToMenu()}
            handleCreate={(round, section, title) => this.createTimeline(round, section, title)}
          />
          break;
        case 'demo':
          mainComponent = <DemoSettingController
            timeline={this.state.timeline}
            loginUser={this.state.loginUser}
            handleChangeScreen={(screenName, timelineId) => { this.changeScreen(screenName, timelineId); }}
            handleStartDemo={(timelineId, goBackMinutes) => { this.startDemo(timelineId, goBackMinutes); }}
            headerAccount={headerAccount}
            backToMenu={() => this.backToMenu()}
            handleCreate={(round, section, title) => this.createTimeline(round, section, title)}
          />
          break;
      }
      const modalNameTable = {
        accountModal: "gl-active",
        editTextModal: "al-active",
        operationModal: "op-active",
        commonModal: "al-active",
      };
      const modalClassName = modalNameTable.hasOwnProperty(modalType) ? modalNameTable[modalType] : "";
      if (this.state.overlayScreenMenu === 'changePassword') {
          mainComponent = <NewPassword
            loginUser={this.state.loginUser}
            headerAccount={headerAccount}
            handleCancel={() => this.cancelChangePassword()}
            handleComplete={() => this.changePasswordComplete()}
          />
      } else if (this.state.overlayScreenMenu === 'changePasswordComplete') {
        mainComponent = <RequestComplete>
            <div className="button-area">
            <div className="row justify-content-center">
            <div className="col-auto"><button type="button" className="basic-button submit-button t-hover" onClick={() => this.changeScreen('menu')}><span>TOP</span></button></div>
            </div>
            </div>
        </RequestComplete>

      }

      return (
        <>
          { this.state.loading && (
          <div className="loading d-flex align-items-center justify-content-center">
            <div className="loading__inner">
              <div className="loading__unit"></div>
            </div>
          </div>
          )}
          <div className={modalClassName}>
            <AccountModal
              loginUser={loginUser}
              handleClose={() => { this.closeModal() }}
              handleChangePassword={() => { this.changePassword() }}
              handleLogout={() => { this.logout() }}
            />
            { this.state.modalType === "commonModal" && (
              <CommonModal {...this.props} />
            )}
            {mainComponent}
          </div>
        </>
      )

      if (this.state.screenName === 'monitoring') {
        return <>
          <div className="App">
            <Container fluid>
              <TimelineList
                timelineList={this.state.timeline.list}
                currentTimelineId={this.state.timeline.currentSelectId}
                handleChange={event => {
                  this.changeTimelineSelect(event.target.value);
                }}
                handleExecute={() => {
                  this.switchTimeline()
                }}
                handleCreate={(round, section, title) => this.createTimeline(round, section, title)}
                loginUser={this.state.loginUser}
              >

              {this.state.timeline.current && this.state.timeline.current.cars &&
                <>
                  <Button size="sm" onClick={() => {
                    this.changeView("timeline");
                  }}>Timeline View</Button>&nbsp;
                  <Button size="sm" onClick={() => {
                    this.changeView("autoclip");
                  }}>Clip View</Button>
                </>
              }
              {
                this.state.view === 'timeline' && (
                  <TimelineDetail
                    activeTimeline={this.state.timeline.list.find(item => (item.capture_status === 'active'))}
                    timeline={this.state.timeline.current}
                    handleTerminateCar={car_no => {
                      this.terminateTimelineCar(car_no)
                    }}
                    handleReset={() => {
                      this.resetCurrentTimeline()
                    }}
                    handleActivate={() => {
                      this.activateCurrentTimeline()
                    }}
                    handleTerminate={() => {
                      this.terminateCurrentTimeline()
                    }}
                  />
                )
              }
              {
                this.state.view === 'autoclip' && (
                  <>

                    <ClipList
                      activeTimeline={this.state.timeline.list.find(item => (item.capture_status === 'active'))}
                      timeline={this.state.timeline.current}
                      handlePlay={(clip) => {
                        this.playClip(clip)
                      }}
                    />
                  </>
                )
              }
              </TimelineList>
            </Container>
          </div>
        </>;
      }
    } else {
      switch (this.state.screenMenu) {
        case 'logout':
          return <Logout>
            <div className="button-area">
            <div className="row justify-content-center">
            <div className="col-auto"><button type="button" className="basic-button submit-button t-hover" onClick={() => this.changeScreen('login')}><span>TOP</span></button></div>
            </div>
            </div>
          </Logout>
        default:
          return <LoginController login={user => {
            this.login(user);
          }}/>
          break;
      }
    }
  }
}

export default App;
