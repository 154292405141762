import React from "react";
import { startTimeFormat, durationFormat } from "../../../lib/util";
import ClipBlockInfo from "./ClipBlockInfo";

const ClipBlock = (props) => {
  const { clip, isHot, visitedAutoclipTable } = props;
  const customClips = props.timeline.current?.customclips;
  const customClip = clip ? customClips?.find(cc => cc.autoclip_id === clip.autoclip_id) : null;
  return (
       <div className="block-area__base">
         { clip && (
          <button className={"block" + (isHot ? " hot" : "")
            + (visitedAutoclipTable.hasOwnProperty(clip.autoclip_id) ? " visited" : "")
            + (customClip ? " " + (customClip?.is_transmitted ? "transmited" : "stock") : "")
          }
                  onClick={() => { props.handleEditOperation(clip); }}
          >
            <ClipBlockInfo {...props} />
          </button>
           )}
        </div>
    )
};
export default ClipBlock;
