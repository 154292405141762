import React from "react";
import Article from "../common/Article";

import App from "../../App";
import ArchiveTop from "./ArchiveTop";
import ClipList from "./ClipList";
import {apiGet, apiPost} from "../common/API";

class ArchiveController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'list',
      year: "",
      roundId: "",
      carNo: "",
      selectedCarNo: "",
      archiveList: [],
      currentCarClipList: [],
      editClip: "",
      editText: "",
    };
  }

  componentDidMount() {
    // this.props.handleLoading(true);
    apiGet("/archives").then(res => {
      // this.props.handleLoading(false);
      this.setState({archiveList: res.data, year: res.data[res.data.length - 1].year});
    });
  }

  startEditClip(clip) {
    this.setState({editClip: clip, editText: clip.modified_text});
    this.props.handleEditText()
  }

  changeClipText(text) {
    this.setState({editText: text})
  }

  saveClipText() {
    const clip = this.state.editClip;
    const text = this.state.editText;

    apiPost("/autoclips/" + clip.autoclip_id + "/text", { text: this.state.editText }).then(res => {
      apiGet("/archives").then(res => {
        this.setState({archiveList: res.data, editClip: null});
      });
      this.props.handleClose();
    })
  }

  cancelEditClip() {
    this.setState({editClip: null});
    this.props.handleClose();
  }

  selectYear(year) {
    if (year) {
      this.setState({year: year});
    }
  }

  selectRound(roundId) {
      this.setState({roundId: roundId});
  }

  selectCarNo(carNo) {
      this.setState({carNo: carNo})
  }

  showCarArchive() {
    if (this.state.carNo) {
      // this.props.handleLoading(true);
      apiGet("/archives/" + this.state.year + "/" + this.state.roundId + "/" + this.state.carNo).then(res => {
        // this.props.handleLoading(false);
        this.setState({currentCarClipList: res.data, selectedCarNo: this.state.carNo});
      });
    }
  }

  backToMenu(e) {
    e.preventDefault();
    this.props.backToMenu();
  }

  backToArchiveTop(e) {
    e.preventDefault();
    this.setState({selectedCarNo: null})
  }

  render() {
    const {year, roundId, carNo, archiveList, currentCarClipList, selectedCarNo, editText, editClip} = this.state;
    if (!archiveList.length) {
      return null;
    }

    if (selectedCarNo) {
      const rounds = archiveList.filter(round => round.year == year);
      const archiveCar = rounds.find(round => round.round_id == roundId).cars.find(car => car.car_no == carNo);

      return <ClipList
        {...this.props}
        archiveCar={archiveCar}
        currentCarClipList={currentCarClipList}
        year={year}
        roundId={roundId}
        carNo={carNo}
        handleBack={e => this.backToArchiveTop(e)}
        handleStartEditText={clip => { console.log("edit"); this.startEditClip(clip); }}
        handleChangeText={text => { this.changeClipText(text);}}
        handleSaveText={() => { this.saveClipText(); }}
        handleClose={() => { this.cancelEditClip(); }}
        editClip={editClip}
        editText={editText}
      />
    } else {

      return <ArchiveTop {...this.props}
                         archiveList={archiveList}
                         year={year}
                         roundId={roundId}
                         carNo={carNo}
                         handleSelectYear={year => this.selectYear(year)}
                         handleSelectRound={roundId => this.selectRound(roundId)}
                         handleSelectCarNo={carNo => this.selectCarNo(carNo)}
                         handleGo={() => this.showCarArchive()}
                         handleBack={e => this.backToMenu(e)}
              />
    }
  }
}

export default ArchiveController;
