import React from "react";
import Article from "../common/Article";

const AccountModal = (props) => {

  return (
    <>
      <article className="modal modal-global">
        <section className="modal__window">
        <div className="modal__window__inner">

        <div className="modal__close"><button className="t-hover" onClick={props.handleClose}><span></span><span></span></button></div>

        <h3 className="headline">ACCOUNT</h3>

        <div className="global-menu">

        <nav className="global-menu__nav">
        <ul>
        <li><span>{props.loginUser.username}</span></li>
        <li><span>{props.loginUser.login_id}</span></li>
        <li><a className="t-hover" onClick={props.handleChangePassword}>CHANGE OR RESET YOUR PASSWORD </a></li>
        <li><a className="t-hover" onClick={props.handleLogout}>LOGOUT</a></li>
        </ul>
        </nav>

        </div>
        </div>
        </section>
      </article>

    </>
  );
}

export default AccountModal;
