import React from "react";
import Article from "../common/Article";

class MainMenu extends React.Component {
  render() {
    const { timeline, loginUser } = this.props;
    const at = timeline && timeline.list.find(item => (item.capture_status === 'active'));

    return (
      <div className={"authentication " + this.props.pageClass}>
        <Article pageClass="authentication" loginUser={this.props.loginUser} headerAccount={this.props.headerAccount}>

          <div className="navigation">

            <h3 className="headline">MENU</h3>

            <div className="navigation__list">
              <ul>
                { at && (loginUser.is_admin == 1 || loginUser.is_operator == 1 || loginUser.is_viewer == 1) && (
                  <li><a className="round-button t-hover" onClick={() => {
                    this.props.handleChangeScreen('operation', at.timeline_id);
                  }}><span>LIVE GRAPHICS OPERATION
                      (ROUND {at.round_id} {at.section_name.toUpperCase()} {at.title})</span></a></li>
                )}
                { loginUser.is_admin == 1 && at && (
                  <li><a className="round-button t-hover" onClick={() => {
                    this.props.handleChangeScreen('monitoring', at.timeline_id);
                  }}><span>AUTOMATIC GENERATION AUDIO CLIP MONITORING (ROUND {at.round_id} {at.section_name.toUpperCase()} {at.title})</span></a></li>
                )}
                <li><a className="round-button t-hover" onClick={() => {
                  this.props.handleChangeScreen('archive');}}><span>AUDIO CLIP ARCHIVE</span></a></li>
                { loginUser.is_admin == 1 && (
                <li><a className="round-button t-hover" onClick={() => {
                  this.props.handleChangeScreen('setting');}}><span>SETTING</span></a></li>
                  )}
                { (loginUser.is_admin == 1 || loginUser.is_operator )&& (
                <li><a className="round-button t-hover" onClick={() => {
                  this.props.handleChangeScreen('demo');}}><span>DEMONSTRATION REPLAY</span></a></li>
                  )}
              </ul>
            </div>

          </div>

        </Article>
      </div>
    );
  }
}

export default MainMenu;
