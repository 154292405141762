import React from "react";
import Article from "../common/Article";

const LoginForm = (props) => {
  return (
    <>
      <div className="login">
      <Article className="content" login={props.login}>


      <form className="login__form" onSubmit={props.handleLogin}>

      <ul className="flow"><li><strong className="active"><span>1</span></strong></li><li><strong><span>2</span></strong></li></ul>

      <div className="login__form__inner">

      <div className={"input-group" + ((props.formStatus.loginId && props.formStatus.loginId.status=="invalid") ? " invalid-feedback" : "")}>
      <label htmlFor="login-id" className="form-label">USER ID</label>
      <input autoFocus type="text" id="login-id" className="form-control" placeholder="INPUT YOUR ID (E-mail address)"
             value={props.form.loginId}
             onClick={props.handleClearError}
             onChange={e => {props.handleChange({loginId: e.target.value})}}/>
      <div className="valid-feedback">{props.formStatus.loginId && props.formStatus.loginId.message.toUpperCase()}</div>
      </div>
      <div className={"input-group" + ((props.formStatus.password && props.formStatus.password.status=="invalid") ? " invalid-feedback" : "")}>
      <label htmlFor="login-password" className="form-label">PASSWORD</label>
      <input type="password" id="login-password" className="form-control" placeholder="INPUT PASSWORD"
             value={props.form.password}
             onClick={props.handleClearError}
             onChange={e => {props.handleChange({password: e.target.value})}}/>
      <div className="valid-feedback">{props.formStatus.password && props.formStatus.password.message.toUpperCase()}</div>
      </div>
      <div className="button-area">
      <div className="row">
      <div className="col"><button type="reset" className="basic-button button login__button t-hover"
        onClick={() => { props.handleClearError(); props.handleChange({loginId: '', password:''})}}
      ><span>RESET</span></button></div>
      <div className="col"><button type="submit" className="basic-button submit-button login__button t-hover" onClick={props.handleLogin}><span>SUBMIT</span></button></div>
      </div>
      </div>
      <div className="forgot-password" style={{display: "none"}}>
      <a href="#" className="t-hover" onClick={props.handleForgotPassword}>Forgot password?</a>
      </div>

      </div>

      </form>

      </Article>
      </div>
    </>
  );
}

export default LoginForm;
