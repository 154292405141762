import axios from "axios";

const END_POINT = (window.location.hostname === "localhost") ? 'http://localhost:8000/admin' : '/admin';
const XHR_CONFIG = { headers: { 'x-device-authentication': 'sf-teamradio'}, withCredentials: true};

export const apiGet = (url) => {
  return axios.get(END_POINT + url, XHR_CONFIG);
};

export const apiPost = (url, data) => {
  return axios.post(END_POINT + url, data, XHR_CONFIG);
};

export const apiPut = (url, data) => {
  return axios.put(END_POINT + url, data, XHR_CONFIG);
}

export const apiDelete = (url) => {
  return axios.delete(END_POINT + url, XHR_CONFIG);
}
