import React from "react";
import Article from "../common/Article";

const ArchiveTop = (props) => {
  const { archiveList, year, roundId, carNo } = props;

  const yearOptions = Array.from(new Set(archiveList.map(round => round.year)));
  const rounds = props.year ? archiveList.filter(round => round.year == year) : []
  const roundOptions = props.year ? Array.from(new Set(rounds.map(round => round.round_id))) : [];
  const currentRound = (rounds.length && roundId) ? rounds.find(round => round.round_id == roundId) : null;
  const carNoOptions = currentRound ? currentRound.cars : [];

  return (
    <>
      <div className="archive authentication">
      <Article className="content archive-top" {...props}>
        <div className="navigation">

          <h3 className="headline">AUDIO CLIP ARCHIVE</h3>

          <div className="navigation__unit">
            <div className="input-group">
              <div className="row g-4 gy-5 align-items-end row-cols-2 row-cols-md-4">
                <div className="col-auto"><label className="form-label">SELECT YEAR</label>
                  <select
                  className="select-box" value={props.year} onChange={e => { props.handleSelectYear(e.target.value)}}>
                  {
                    yearOptions.map((year, i) => <option key={i} value={year}>{year}</option>)
                  }
                </select></div>
                <div className="col-auto"><label className="form-label">SELECT ROUND</label><select
                  className="select-box" value={props.roundId} onChange={e => { props.handleSelectRound(e.target.value)}}>
                  <option value="">NOT SELECTED</option>
                  {
                    roundOptions.map((round, i) => <option key={i} value={round}>ROUND {round}</option>)
                  }

                </select></div>
                <div className="col-auto"><label className="form-label">SELECT DRIVER</label><select
                  className="select-box" value={props.carNo} onChange={e => { props.handleSelectCarNo(e.target.value)}}>
                  <option value="">NOT SELECTED</option>
                  {
                    carNoOptions.map((car, i) => <option key={i} value={car.car_no}>No.{car.car_no} {car.driver_name.toUpperCase()}</option>)
                  }
                </select></div>
                <div className="col-auto">
                  <button type="button" className="basic-button submit-button t-hover" onClick={() => { props.handleGo(); }}><span>GO</span></button>
                </div>
              </div>
              <div className="valid-feedback">ERROR "TIME-LINE NAME" IS NOT ENTERED. , "TIME-LINE STATUS" IS NOT
                SELECTED.
              </div>
            </div>
          </div>

          <div className="back-button-area">
            <button className="back-button t-hover" onClick={props.handleBack}><span>BACK</span></button>
          </div>

        </div>

      </Article>
      </div>
    </>
  );
}

export default ArchiveTop;
