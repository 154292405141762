import React from "react";

class Article extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'list',
      selectedCarNo: null,
    };
  }

  showUserModal() {

  }


  render() {
    return (
      <>
      <article className={this.props.className || "content"}>

        <header className="header">
          <div className="header__inner"><h1><strong className="header__logo"></strong><strong
            className="header__title"><span className="d-none d-md-table-cell">SUPER FORMULA TEAM RADIO DIGITIZATION SYSTEM</span></strong>
          </h1>
            {this.props.headerAccount}
          </div>

        </header>

        <div className="content__inner">
          {this.props.children}
        </div>

        <footer className="footer"><p className="footer__copyright">Copyright &copy; Japan Race Promotion Inc. All
          rights reserved.</p></footer>

      </article>
      </>

    );
  }
}


export default Article;
