import React from "react";
import ClipBlock from "./ClipBlock";
import LockOnBlock from "./LockOnBlock";

const LockOnArea = (props) => {

  const { lockOnList } = props;
  return (
    <div className="locked-on-area">
      {lockOnList.map((lockOnCar, i) => {
        return <LockOnBlock key={lockOnCar.car_no} {...props}　{...lockOnCar} />
      })}

    </div>
  )
};
export default LockOnArea;
