import React, {useState} from "react";
import Article from "../common/Article";
import {format} from "date-fns";
import {Button} from "react-bootstrap";
import CustomClip from "./sub/CustomClip";
import ClipBlockInfo from "./sub/ClipBlockInfo";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {getEndPoint, startTimeFormat, getObjectStorageEndPoint} from "../../lib/util";

import { SF_YEAR, getDriverNameRows } from "../../lib/util";

const TimelineOperation = (props) => {
  const t = props.timeline.current;
  const { visitedAutoclipTable } = props;
  const customClips = t?.customclips;
  const expandRatio = 5;
  const timelineShrinkWidthPerSecond = 6;
  const [ draggingState, setDraggingState ] = useState({target: null, startX: 0, startPos: 0});
  const [ scrollPos, setScrollPos ] = useState(0);
  const [ timelineWidth, setTimelineWidth ] = useState(5000);
  const [ timelineWidthPerSecond, setTimelineWidthPerSecond ] = useState(expandRatio * timelineShrinkWidthPerSecond);
  const [ timelineIsShrink, setShrinkTimeline ] = useState(false);

  let teamCode = '', bgAlternate = true;
  let delta = 2000;
  /*
  const timelineFrame = document.getElementById('timeline-frame');
  if (timelineFrame) {
    delta = Math.min(timelineFrame.clientWidth / 2, delta);
  }
   */
  if (!t.cars) {
    return null;
  }

  const expandTimeline = (x = null) => {
    if (timelineIsShrink) {
      const tf = document.getElementById('timeline-frame');
      const cw = tf.clientWidth;
      const centerPos = x ? (cw - (x - tf.offsetLeft)) : cw / 2;
      const newPos = scrollPos > 0 ? (scrollPos + centerPos) * expandRatio - centerPos : 0;
      setShrinkTimeline(false);
      setTimelineWidthPerSecond(expandRatio * timelineShrinkWidthPerSecond);
      scrollTimeline(newPos, expandRatio * timelineShrinkWidthPerSecond)
    }
  }
  const shrinkTimeline = (x = null) => {
    if (!timelineIsShrink) {
      const tf = document.getElementById('timeline-frame');
      const cw = tf.clientWidth;
      const centerPos = x ? (cw - (x - tf.offsetLeft)) : cw / 2;
      const newPos = (scrollPos + centerPos) / expandRatio - centerPos;
      setShrinkTimeline(true);
      setTimelineWidthPerSecond(timelineShrinkWidthPerSecond);
      scrollTimeline(newPos, timelineShrinkWidthPerSecond)
    }
  }

  const scrollTimeline = (newScrollPos, newTimelineWidthPerSecond = timelineWidthPerSecond) => {
    const cw = document.getElementById('timeline-frame').clientWidth;
    const maxScrollPos = Math.max(newTimelineWidthPerSecond * timelineSecond - cw, 0);

    if (newScrollPos < 0) {
      newScrollPos = 0;
    }
    if (newScrollPos > maxScrollPos) {
      newScrollPos = maxScrollPos;
    }
    setScrollPos(newScrollPos);
  }

  const scrollTimelineDelta = delta => {
    scrollTimeline(scrollPos + delta)
  };

  const timelineDragStart = (e) => {
    setDraggingState({target: true, startX: e.clientX, startPos: scrollPos});
  };

  const timelineDragEnd = () => {
    setDraggingState({target: null, startX: 0, startPos: 0});
  }

  const timelineDrag = (e) => {
    const d = draggingState;
    if (!e.buttons) {
      timelineDragEnd()
    }
    scrollTimeline(d.startPos + (e.clientX - d.startX));
  };

  const timelineCarClipMin = t.cars.map(
    car => car.clips.length ?
      car.clips.reduce((ac, cv) => { const c = new Date(cv.start_datetime); return ((ac && ac < c ) ? ac : c);}, null) : null)
    .filter(c => c)
    .reduce((ac, cv) => ((ac && ac < cv) ? ac : cv), null);
  const timelineCarClipMax = t.cars.map(
    car => car.clips.length ? car.clips.reduce((ac, cv) => { const c = new Date(cv.start_datetime); c.setMilliseconds(c.getMilliseconds() + cv.duration_ms); return ((ac && ac > c) ? ac : c)}, null) : null)
    .filter(c => c)
    .reduce((ac, cv) => ((ac && ac > cv) ? ac : cv), null);
  const timelineSecond = Math.ceil((timelineCarClipMax - timelineCarClipMin) / 1000) + 25;

  const gaugeInterval = 1000 * 60 * (timelineIsShrink ? expandRatio : 1);
  let currentGauge = Math.ceil(timelineCarClipMin / gaugeInterval);
  const gaugeEnd = Math.floor(timelineCarClipMax / gaugeInterval);
  const gaugeList = [];

  //console.log(timelineCarClipMin, timelineCarClipMax, timelineSecond);
  if (timelineCarClipMax - timelineCarClipMin) {
    while (gaugeEnd >= currentGauge) {
      let time = new Date(currentGauge * gaugeInterval);
      let pos = timelineWidthPerSecond * (timelineCarClipMax - time) / 1000;
      gaugeList.push({time: time, position: pos});
      currentGauge++;
    }
  }

  return (
      <>
      <div className="livegraphics authentication">
      <Article className="content" {...props}>
        <div className="livegraphics__ui">
          <div className="livegraphics__header d-flex align-items-center justify-content-between">
            <div className="left-side">
              <div className="row gx-4">
                <div className="col-auto">
                  <button className="back-button t-hover" onClick={props.handleBack}><span>BACK</span></button>
                </div>
                <div className="col-auto">
                  <div className="mini-title"><strong><span>ROUND</span></strong><span className="mini-title__title">{t.year} ROUND {t.round_id} - {t.section_name.toUpperCase()}</span>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="mini-title"><strong><span>TIME LINE</span></strong><span
                    className="mini-title__title">{t.title}</span></div>
                </div>
              </div>
            </div>
            <div className="right-side">
              <div className="row gx-1">
                {false && (
                  <>
                    <div className="col-auto">
                      <button className="mini-tab on" id="car-order"><strong><span>CAR ORDER</span></strong></button>
                    </div>
                    <div className="col-auto">
                      <button className="mini-tab ranking" id="ranking"><strong><span>RANKING</span></strong></button>
                    </div>
                  </>
                )}
                <div className="col-auto">
                  <button className={"mini-tab" + (props.autoSilenceDetection ? ' on' : '')} id="silence-detection" onClick={() => {
                    props.handleChangeSilenceDetectionMode()
                  }}>
                    <strong><span>SILENCE DETECTION</span></strong></button>
                </div>

                <div className="col-auto">
                  <button className="mini-tab" id="b-mode" onClick={() => {
                    props.handleChangeViewMode('block');
                  }}><strong><span>BLOCK VIEW</span></strong></button>
                </div>

                <div className="col-auto">
                  <button className="mini-tab on" id="t-mode" onClick={() => {
                    props.handleChangeViewMode('timeline');
                  }}><strong><span>TIME-LINE VIEW</span></strong></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CustomClip {...props} />

        <div className="time-line-view">
          <div className="time-line-view__scale">


            <div className="backward">
              <nav className="backward__header">
                <div className="row gx-1">
                  <div className="col-auto">
                    <button className={"mini-tab scale-back " + (timelineIsShrink ? 'on' : '')}  id="scale-back" onClick={() => shrinkTimeline(true)}><strong><span></span></strong></button>
                  </div>
                  <div className="col-auto">
                    <button className={"mini-tab expand " + (timelineIsShrink ? '' : 'on')} id="expand" onClick={() => expandTimeline()}><strong><span></span></strong></button>
                  </div>
                </div>
              </nav>
            </div>

            <div className="time-width" style={{overflow:'hidden'}}>
              <div className="time-width__inner" style={{
                width: (timelineSecond * timelineWidthPerSecond) + 'px',
                right:-scrollPos + 'px',
                transition:'all ' + (draggingState.target ? '0s' : '0.3s'),
            }}>
                <div className="time-width__scale">
                  {
                    gaugeList.map((gauge, i) =>
                        <div key={gauge.time.toString()} className="time-width__scale__lap" style={{color:'red',right: (gauge.position + "px")}}><span>{startTimeFormat(gauge.time.toString()).replace(/:\d+$/, '')}</span></div>
                    )
                  }


                  { false && (<>
                  <div className="time-width__scale__lap" style={{"right":"0%"}}><strong>LAP</strong><span>20</span></div>
                  <div className="time-width__scale__lap" style={{"right":"95%"}}><strong>LAP</strong><span>19</span></div>
                  <span className="bar bar__l"></span><span className="bar bar__s"></span><span
                  className="bar bar__l"></span><span className="bar bar__s"></span><span className="bar bar__l"></span><span
                  className="bar bar__s"></span><span className="bar bar__l"></span>
                    </>)}
                  </div>
              </div>
            </div>



            <div className="forward">
              <nav className="forward__header"></nav>
            </div>


          </div>

          <div className="time-line-view__driver scroll-box">
            <div className="backward">
              { t.cars.map((car, i) => {
                  let driverName = car.driver_name;
                  let driverPrefix = "";
                  let found;
                  if (found = driverName.match(/^(\W+)(.*)/)) {
                    driverPrefix = found[1];
                    driverName = found[2];
                  }
                return (
                    <div key={i} className={"backward__driver-wrap driver-wrap"
                      + (" car-" + car.car_no)
                      + ((car.capture_status=='terminated' && !props.virtualCurrentTime) ? " terminate" : "")
                    }>
                      <button className="driver-wrap__tag">
                        <dl>
                                    { (car.last_process && car.last_process?.status.match(/voice/)) && (
          <dt className="coming-clip"><p className="coming-clip__info">
            <strong>{car.driver_code}</strong><span>{Number.parseFloat(car.last_process?.voice_sec || 0).toFixed(0)} <small>s</small></span></p></dt>
            )
          }
                          <dt><strong className="num-box"><span>{car.car_no}</span></strong>
                            <figure className="photo"><img src={`../driver-img/${SF_YEAR}/${car.driver_code}.png`} alt=""/></figure>
                          </dt>
                          <dd>
                            <h4 className={"driver " + (car.driver_name.match(/^\W+/) ? "special-name" : "normal-name")}>{car.driver_name.split(/ /).map((s, i) => <span key={i}>{
            s.toUpperCase()}</span>)}
          </h4>
                          </dd>
                        </dl>
                      </button>
                      <button className="backward__button" onClick={() => { scrollTimelineDelta(delta); }}></button>
                    </div>
                  )}
              )}
            </div>

            <div id="timeline-frame" className="time-width" style={{
                  height: (t.cars.length * 13.7) + 'rem',
                  transition:'all ' + (draggingState.target ? '0s' : '0.3s'),
            }}>
              <div className="timeline-frame-inner" style={
                {
                  width: (timelineSecond * timelineWidthPerSecond) + 'px',
                  right:-scrollPos + 'px',
                  transition:'all ' + (draggingState.target ? '0s' : '0.3s'),
                }
              }
                   onMouseDown={e => { timelineDragStart(e); }}
                   onMouseMove={e => { draggingState.target && timelineDrag(e);}}
                   onMouseUp={e => { timelineDragEnd(); }}
                   onDoubleClick={e => { timelineIsShrink ? expandTimeline(e.clientX) : shrinkTimeline(e.clientX); }}
              >
{ t.cars.map((car, i) => {
  if (teamCode !== car.team_code) {
    bgAlternate = !bgAlternate;
    teamCode = car.team_code;
  }
  return (
              <div key={i} className={"time-width__driver-wrap"
                  + (" car-" + car.car_no)
                  + (car.capture_status=='terminated' ? " terminate" : "")
                  + (car.team_code ? ' team-' + car.team_code.toLowerCase() : '')
                  + (car.engine_code ? ' engine-' + car.engine_code.toLowerCase() : '')
                  + (bgAlternate ? ' bg-alternate' : '')
              }

              >
                { car.clips.map(clip => {
                  const clipTime = new Date(clip.start_datetime);
                  clipTime.setMilliseconds(clipTime.getMilliseconds() + clip.duration_ms)
                  const clipPos = timelineWidthPerSecond * (timelineCarClipMax - clipTime) / 1000;
                  const clipWidth = (timelineWidthPerSecond * clip.duration_ms / 1000) + 'px';
                  const customClip = clip ? customClips?.find(cc => cc.autoclip_id === clip.autoclip_id) : null;

                  return (
                      <button key={clip.autoclip_id} className={
                        "time-width__waveform" + (timelineIsShrink ? ' block-hidden': '')
                         + (visitedAutoclipTable.hasOwnProperty(clip.autoclip_id) ? " visited" : "")
                         + (customClip ? " " + (customClip?.is_transmitted ? "transmited" : "stock") : "")

                      } style={{"right": clipPos,"width": clipWidth}}
                              onClick={() => { !draggingState.target && props.handleEditOperation(clip); }}>
                        {
                        <div className="block">
                          <ClipBlockInfo {...props} clip={clip} />
                        </div>
                          }
                        <div className="time-width__waveform__inner">
                          { ((timelineIsShrink && clip.duration_ms >= 5000) || (!timelineIsShrink && clip.duration_ms >= 4000)) ? (
                          <LazyLoadImage key={clip.autoclip_id} effect="blur" threshold={100} delayTime={clip.duration_ms > 15000 ? 0 : 1000} visibleByDefault={clip.duration_ms > 20000} src={
                            clip.waveform_object_key ? `${getObjectStorageEndPoint()}/${clip.waveform_object_key}` : `${getEndPoint()}/autoclips/${clip.autoclip_id}/waveform`} />
                          ) : (
                            <img src={
                            clip.waveform_object_key ? `${getObjectStorageEndPoint()}/${clip.waveform_object_key}` : `${getEndPoint()}/autoclips/${clip.autoclip_id}/waveform`} />
                          )
                          }
                        </div>
                      </button>
                    )
                  }
                  )}
              </div>

              )}
              )}
              </div>
            </div>



            <div className="forward">
              { t.cars.map((car, i) => (
              <div key={i} className="forward__driver-wrap driver-wrap">
                <button className="forward__button" disabled={scrollPos === 0} onClick={() => { scrollTimelineDelta(-delta); }}></button>
                <button className="forward__button current" disabled={scrollPos === 0} onClick={() => { setScrollPos(0)}}></button>
              </div>
              )
              )}
            </div>


          </div>

        </div>



      </Article>
      </div>
    </>
  );
}

export default TimelineOperation;
