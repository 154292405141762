import React from "react";
import ClipBlock from "./ClipBlock";

const HistoryArea = (props) => {

  const { car } = props;

  return true && (
    <div className="driver-wrap__history-area scroll-box-s">
              { car.historyClips.map((clip, i) => {
          return <ClipBlock {...props} clip={clip} key={clip.autoclip_id} />
        })}

    </div>
    )
};
export default HistoryArea;
