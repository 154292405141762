import React, {useState} from "react";
import Article from "../common/Article";

const DemoSetting = (props) => {
  const [round, setRound] = useState(0);
  const [section, setSection] = useState("development");
  const [title, setTitle] = useState("");

  const [pastRound, setPastRound] = useState(9);
  const [pastTimelineId, setPastTimelineId] = useState(0);
  const [backMinutes, setBackMinutes] = useState(10);
  const currentTimeline = props.timeline.list.filter(timeline => (timeline.capture_status==="active" || timeline.capture_status==="inactive"));
  const pastTimeline = props.timeline.list.filter(timeline => (timeline.capture_status==="terminated" && timeline.round_id == pastRound));

  return (
    <>
      <div className="setting authentication">
      <Article className="content" {...props}>


        <div className="navigation">

          <h3 className="headline">DEMONSTRATION REPLAY SETTING</h3>

          <div className="navigation__unit">
            <h4>TIME-LINE TO USE</h4>
            <div className="input-group">
              <div className="row g-4 gy-5 align-items-end">
                <div className="col-auto"><label className="form-label">SELECT ROUND</label><select
                  className="select-box" value={pastRound} onChange={event => setPastRound(event.target.value)}>
                  <option value="">NOT SELECTED</option>
                  <option value="0">TEST RUN (ROUND 0)</option>
                  <option value="1">ROUND 1</option>
                  <option value="2">ROUND 2</option>
                  <option value="3">ROUND 3</option>
                  <option value="4">ROUND 4</option>
                  <option value="5">ROUND 5</option>
                  <option value="6">ROUND 6</option>
                  <option value="7">ROUND 7</option>
                  <option value="8">ROUND 8</option>
                  <option value="9">ROUND 9</option>
                </select></div>
                <div className="col-auto"><label className="form-label">SELECT TIME LINE</label><select
                  className="select-box" value={pastTimelineId} onChange={event => setPastTimelineId(event.target.value)}>
                  <option>NOT SELECTED</option>
                  {pastTimeline.map((t, i) => {
                    return (
                      <option key={i} value={t.timeline_id}>{t.section_name.toUpperCase()} {t.title} ({t.capture_status.toUpperCase()})</option>
                    );
                  })}
                </select></div>
                <div className="col-auto"><label className="form-label">GO BACK</label><select
                  className="select-box" value={backMinutes} onChange={event => setBackMinutes(event.target.value)}>
                  <option value={1}>1 MIN</option>
                  <option value={5}>5 MIN</option>
                  <option value={10}>10 MIN</option>
                  <option value={30}>30 MIN</option>
                  <option value={45}>45 MIN</option>
                  <option value={60}>60 MIN</option>
                  <option value={75}>75 MIN</option>
                  <option value={90}>90 MIN</option>
                </select></div>
                <div className="col-auto">
                  <button type="button" className="basic-button submit-button t-hover" onClick={() => {
                    pastTimelineId && props.handleStartDemo(pastTimelineId, backMinutes);
                  }} ><span>GO</span></button>
                </div>
              </div>
              <div className="valid-feedback">ERROR "TIME-LINE" IS NOT SELECTED.</div>
            </div>
          </div>



          <div className="back-button-area">
            <button className="back-button t-hover" onClick={props.handleBack}><span>BACK</span></button>
          </div>


        </div>


      </Article>
      </div>
    </>
  );
}

export default DemoSetting;
