const BEEP_FREQUENCY = 880;
const BEEP_VOLUME = 0.15;

const CUT_AREA_MIN_SECOND = 0.4;
const CUT_AREA_MIN_SILENT_SECOND = 1.25;

// カット後に残す秒数（半分の秒数が両側に均等に付与される）
const CUT_AREA_SILENT_MARGIN_SECOND = 1.0;
const CUT_AREA_SILENT_THRESHOLD = 0.025;

const audioCtx = new AudioContext();


export function findCutList2(audioFeature) {

  console.log(audioFeature);
  const sampleRate = parseInt(audioFeature.sample_rate, 10);
  const bufLength = parseInt(audioFeature.size, 10)
  const silentFeature = audioFeature.silent_feature;
  const minCount = CUT_AREA_MIN_SILENT_SECOND * sampleRate;
  let pos = 0, startPos = 0;
  let silent = false;
  const silenceList = [];
  silentFeature.forEach((a, i) => {
    const [sound, sample] = a;
    if (sound <= CUT_AREA_SILENT_THRESHOLD) {
      if (!silent) {
        silent = true;
        startPos = pos;
      }
    } else {
      if (silent) {
        if (pos - startPos > minCount) {
          silenceList.push([startPos, pos - startPos, 0]);
        }
        silent = false;
      }
    }
    pos += sample;
    if (i >= silentFeature.length - 1 && silent) {
//        console.log("silent End: " + pos + ", duration: " + (pos - startPos) + ", minCount: " + minCount);
        if (pos - startPos > minCount) {
          silenceList.push([startPos, pos - startPos, 0]);
        }
    }
  });
  const margin = CUT_AREA_SILENT_MARGIN_SECOND / 2 * sampleRate;
  const cutList = silenceList
    .map(silence => ({start: (silence[0]) / bufLength, end: (silence[0] + silence[1]) / bufLength}))
    .map(silence => ({
      start: silence.start <= 0.001 ? silence.start : silence.start + margin / bufLength,
      end: silence.end >= 0.999 ? silence.end : silence.end - margin / bufLength,
    }))
    .filter(silence => ((silence.end - silence.start) * bufLength / sampleRate > CUT_AREA_MIN_SECOND));
  return cutList;
}


export function findCutList(buf) {
  function findSilence(arr, minCount = 1) {
    let encoding = [],
      previous,
      i,
      count;

    for (count = 1, previous = arr[0], i = 1; i < arr.length; i++) {
      if (arr[i] !== previous) {
        if (count >= minCount && !previous) {
          encoding.push([i - count, count, previous]);
        }
        count = 1;
        previous = arr[i];
      } else {
        count++;
      }
    }

    /**
     * Add a last pair
     */
    if (count >= minCount && !previous) {
      encoding.push([i - count, count, previous]);
    }

    return encoding;
  }

  const thresholdData = buf.getChannelData(0).map(value=> Math.abs(value) > CUT_AREA_SILENT_THRESHOLD);

  const silenceList = findSilence(thresholdData, CUT_AREA_MIN_SILENT_SECOND * buf.sampleRate);
  console.log(silenceList);
  const margin = CUT_AREA_SILENT_MARGIN_SECOND / 2 * buf.sampleRate;
  const cutList = silenceList
    .map(silence => ({start: (silence[0]) / buf.length, end: (silence[0] + silence[1]) / buf.length}))
    .map(silence => ({
      start: silence.start <= 0.001 ? silence.start : silence.start + margin / buf.length,
      end: silence.end >= 0.999 ? silence.end : silence.end - margin / buf.length,
    }))
    .filter(silence => ((silence.end - silence.start) * buf.length / buf.sampleRate > CUT_AREA_MIN_SECOND));
  console.log(cutList);
  return cutList;
}


export function getCue(buf, cueStart = 0, cueEnd = buf.length) {
    const newData = buf.getChannelData(0).slice(cueStart, cueEnd);
    const newLength = newData.length;
    if (newLength <= 0) {
      return null;
    }
    const newBuf = audioCtx.createBuffer(1, newLength, buf.sampleRate);
    newBuf.getChannelData(0).set(newData);
    return newBuf;
  }

export function crossFadeShrink(buf, speed = 1) {
    if (speed <= 1) {
      return buf;
    }
    const msec = 22000 / BEEP_FREQUENCY;
    const stretch = 1 / speed;
    const sourceData = buf.getChannelData(0);

    const newLength = Math.floor(buf.length * stretch);
    const newBuf = audioCtx.createBuffer(1, newLength, buf.sampleRate);
    const destData = newBuf.getChannelData(0);

    const keepSample = parseInt(buf.sampleRate / (1000 / msec), 10);
    const blockSample = keepSample * (speed);
    const gutterSample = blockSample - keepSample;
    const crossfadeSample = gutterSample / 10;
    let p = 0;
    let lastSample = 0.0;

    for (let i = 0; i < newLength; i++) {
      let blockCount = Math.floor(i / keepSample);
      let p = i + (blockCount * gutterSample);

      let crossfade = (blockCount >= 1 && (i % keepSample) < crossfadeSample);
      if (crossfade) {
        let e = ((i % keepSample) % crossfadeSample) / crossfadeSample;
        destData[i] = sourceData[i + ((blockCount - 1) * gutterSample)] * (1 - e) + sourceData[p] * e;
      } else {
        destData[i] = sourceData[p];
      }
    }
    return newBuf;
  }
export function applyBeep(buf, beepList) {
    let newData = buf.getChannelData(0).slice();
    const len = newData.length
    const sampleRate = buf.sampleRate;
    const beepFreq = BEEP_FREQUENCY;
    const volume = BEEP_VOLUME;
    const cutList = [];

    const beepFunc = (i, v, fromStart, untilEnd) => {
      const sampleFreq = sampleRate / BEEP_FREQUENCY;
      const s = Math.sin(i / (sampleFreq / Math.PI / 2)) * volume;
      if (fromStart < 100) {
        return s * (fromStart / 100) + v * (1 - fromStart / 100);
      }
      if (untilEnd < 100) {
        return s * (untilEnd / 100) + v * (1 - untilEnd / 100);
      }
      return s;
    }

    beepList.forEach((beep, i) => {
      const start = parseInt(beep.start * len, 10);
      const end = parseInt(beep.end * len, 10);
      if (beep.type === 'cut') {
        console.log(beep)
        console.log(start, end)
        console.log(len)
        cutList.push({startPos: start, endPos: end});
      } else if (beep.type === 'beep') {
        newData = newData.map((value, i) => {
          return (i >= start && i < end) ? beepFunc(i, value, i - start, end - i) : value;
        })
      }
    });
    cutList.sort((a, b) => b.startPos - a.startPos).forEach((cut, i) => {
      const tempData = newData.slice(0, newData.length - (cut.endPos - cut.startPos));
      console.log(cut)
      tempData.set(newData.slice(cut.endPos), cut.startPos);
      newData = tempData;
    });
    console.log(newData.length);

    const newLength = newData.length;
    if (newLength <= 0) {
      return null;
    }
    const newBuf = audioCtx.createBuffer(1, newLength, buf.sampleRate);
    newBuf.getChannelData(0).set(newData);
    return newBuf;
}
